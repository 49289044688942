<template>
  <base-layout>
    <ion-content class="has-header has-subheader">

      <div class="container-fluid p-0 p-md-3">
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-0 g-md-4 row-eq-height justify-content-start">
          <template v-for="exhibitor in exhibitors" :key="exhibitor.id">
            <exhibitor-item :exhibitor="exhibitor" v-if="exhibitor"></exhibitor-item>
          </template>
        </div>
      </div>

    </ion-content>
  </base-layout>
</template>
<script>
import {alertController, IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import ExhibitorItem from "@/components/ExhibitorItem";

export default defineComponent({
  name: "Exhibitors",
  data() {
    return {
      exhibitors: [],
      search: "",
      filtered: []
    };
  },
  components: {
    IonContent,
    ExhibitorItem
  },
  methods: {
    ...mapActions("exhibitors", ["getListForLocation"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    },
    async showDisclaimer(exhibitor) {
      if (exhibitor.disclaimer && exhibitor.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: exhibitor.name,
              message: '<strong>' + exhibitor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(exhibitor.name);
                    if (exhibitor.disclaimer_link) {
                      window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + exhibitor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      } else if (exhibitor.disclaimer_link && exhibitor.disclaimer_link.length > 10) {
        window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
      } else {
        this.$router.push('/app/exhibitors/' + exhibitor.id);
      }

    }
  },
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),

  },
  async created() {
    let list = await this.getListForLocation(this.$route.params.id);
    list = list.sort((a, b) => {
      if (a.name) {
        return a.name.localeCompare(b.name);
      }
    });
    this.exhibitors = this.groupList(list, 'name')
  },
  watch: {
    '$route':
        {
          immediate: true,
          async handler(route) {
            if (route.name == 'ExhibitorsByLocation') {
              let list = await this.getListForLocation(route.params.id);
              list = list.sort((a, b) => {
                if (a.name) {
                  return a.name.localeCompare(b.name);
                }
              });
              this.exhibitors = this.groupList(list, 'name')

            }
          }
        },
    updateFinished: async function (newVal) {
      if (newVal) {
        let list = await this.getListForLocation(this.$route.params.id);
        list = list.sort((a, b) => {
          if (a.name) {
            return a.name.localeCompare(b.name);
          }
        });
        this.exhibitors = this.groupList(list, 'name')
      }
    },
  },
});
</script>
<style lang="scss">
a {
  text-decoration: none;
}
</style>
